const enUs = {
  authPackage: {
    log_in_to_menti_account: 'Log in to your Mentimeter account',
    login_facebook: 'Log in with Facebook',
    login_google: 'Log in with Google',
    login_microsoft: 'Log in with Microsoft',
    authenticate_sso: 'Authenticate with SSO',
    forgot_password: 'Forgot password?',
    login_sso: 'Log in with SSO',
    login: 'Log in',
    login_form_password_label: 'Your password',

    signup_facebook: 'Sign up with Facebook',
    signup_google: 'Sign up with Google',
    signup_microsoft: 'Sign up with Microsoft',
    sso_hint_title: 'Join {{companyName}}’s workspace',
    sso_hint_body:
      'Your organization already has a Mentimeter workspace that you can join.',
    sso_hint_button_text: 'Sign up via SSO',
    workspace_hint_title: 'Join {{companyName}}’s workspace',
    workspace_hint_body:
      'Your organization already has a Mentimeter workspace that you can join',
    workspace_hint_button_text: 'Sign up',

    or_email: 'or using email',

    form_required: 'Please fill in this field!',

    form_email_label: 'Work email',
    form_email_validation: 'Did you enter the email address correctly?',
    form_email_spellcheck: 'Did you mean {{suggestion}}?',

    form_password_label: 'Choose a password',
    form_password_placeholder: 'Very secret password',
    form_password_hint: 'At least {{passwordMinLength}} characters',
    form_password_hint_signup: 'At least 8 characters',
    form_password_validation_short: 'Aren’t you a bit short for a password?',
    form_password_validation_long:
      'Passwords cannot be longer than 50 characters',

    form_name_label: 'First and last name',
    form_name_placeholder: 'Brienne of Tarth',
    form_name_validation_short: 'Please enter both your first and last name!',
    form_name_validation_emoji:
      "We also love a good emoji, but unfortunately, they don't make the best names. Let's just stick to letters for now.",
    form_name_validation_punctuation_marks:
      'Sorry, you can’t include the following characters: @ ] } [ {',

    form_signup_button: 'Sign up',

    form_disclaimer:
      'By signing up you accept our <1>terms of use</1> and <3>policies</3>.',

    create_free_account: 'Create a free account',
    already_have_account: 'Already have an account?',
    no_account_yet: 'No account yet?',

    work_email_promote:
      'We recommend using your work or school email to keep things separate',
  },
};

type Locale = typeof enUs;

const ptBr: Locale = {
  authPackage: {
    log_in_to_menti_account: 'Faça login com sua conta Mentimeter',
    login_facebook: 'Login com sua conta do Facebook',
    login_google: 'Login com sua conta Google',
    login_microsoft: 'Login com sua conta do Microsoft',
    authenticate_sso: 'Authenticate with SSO',
    forgot_password: 'Esqueci minha senha?',
    login_sso: 'Login com SSO',
    login: 'Login',
    login_form_password_label: 'Sua senha',

    signup_facebook: 'Cadastre-se com o Facebook',
    signup_google: 'Cadastre-se com a Google',
    signup_microsoft: 'Cadastre-se com a Microsoft',
    sso_hint_title: 'Juntar-se à área de trabalho de {{companyName}}',
    sso_hint_body:
      'A sua organização já possui uma área de trabalho em Mentimeter a qual você pode se juntar.',
    sso_hint_button_text: 'Cadastre-se via SSO',
    workspace_hint_title: 'Juntar-se à área de trabalho de {{companyName}}',
    workspace_hint_body:
      'A sua organização já possui uma área de trabalho em Mentimeter a qual você pode se juntar.',
    workspace_hint_button_text: 'Cadastre-se',

    or_email: 'ou use seu email',

    form_required: 'Por favor, preencha este campo!',

    form_email_label: 'Correo electrónico de trabajo',
    form_email_validation: 'Você digitou o endereço de e-mail corretamente?',
    form_email_spellcheck: 'Você quis dizer {{suggestion}}?',

    form_password_label: 'Escolha sua senha',
    form_password_placeholder: 'Senha secreta',
    form_password_hint: 'Mínimo {{passwordMinLength}} caracteres',
    form_password_hint_signup: 'Mínimo 8 caracteres',
    form_password_validation_short: 'Sua senha é muito curta!',
    form_password_validation_long:
      'As senhas não podem ter mais que 50 caracteres',

    form_name_label: 'Nome e sobrenome',
    form_name_placeholder: 'Brienne de Tarth',
    form_name_validation_short: 'Por favor, insira seu nome e sobrenome!',
    form_name_validation_emoji:
      'Nós também adoramos um bom emoji, mas, infelizmente, eles não podem ser utilizados como nomes. No momento, temos que nos limitar às letras.',
    form_name_validation_punctuation_marks:
      'Desculpe, não pode incluir os seguintes caracteres: @ ] } [ {',

    form_signup_button: 'Cadastre-se',

    form_disclaimer:
      'Ao se inscrever, você aceita nossos <1>termos</1> e <3>políticas de uso</3>.',

    create_free_account: 'Crie uma conta gratuita',
    already_have_account: 'Já possui uma conta?',
    no_account_yet: 'Ainda não possui uma conta?',

    work_email_promote:
      'Recomendamos usar seu e-mail de trabalho ou escola para manter tudo separado',
  },
};

const esEs: Locale = {
  authPackage: {
    log_in_to_menti_account: 'Inicia sesión en tu cuenta de Mentimeter',
    login_facebook: 'Inicia sesión con Facebook',
    login_google: 'Inicia sesión con Google',
    login_microsoft: 'Inicia sesión con Microsoft',
    authenticate_sso: 'Authenticate with SSO',
    forgot_password: '¿Se te olvidó tu contraseña?',
    login_sso: 'Inicio de sesión único',
    login: 'Inicia sesión',
    login_form_password_label: 'Tu contraseña',
    signup_facebook: 'Regístrate con Facebook',
    signup_google: 'Regístrate con Google',
    signup_microsoft: 'Registrate con Microsoft',
    sso_hint_title: 'Únete al espacio de trabajo de {{companyName}}',
    sso_hint_body:
      'Tu organización ya tiene un espacio de trabajo de Mentimeter al que te puedes unir.',
    sso_hint_button_text: 'Inicia sesión con SSO',
    workspace_hint_title: 'Únete al espacio de trabajo de {{companyName}}',
    workspace_hint_body:
      'Tu organización ya tiene un espacio de trabajo de Mentimeter al que te puedes unir.',
    workspace_hint_button_text: 'Inicia sesión',
    or_email: 'o usando tu correo electrónico',
    form_required: '¡Por favor completa este campo!',
    form_email_label: 'E-mail de trabalho',
    form_email_validation:
      '¿Ingresaste tu dirección de correo electrónico correctamente?',
    form_email_spellcheck: '¿Quisiste decir {{suggestion}}?',
    form_password_label: 'Elije una contraseña',
    form_password_placeholder: 'Contraseña muy secreta',
    form_password_hint: 'Al menos {{passwordMinLength}} caracteres',
    form_password_hint_signup: 'Al menos 8 caracteres',
    form_password_validation_short: '¿No es un poco corto para una contraseña?',
    form_password_validation_long:
      'Las contraseñas no pueden tener más de 50 caracteres',

    form_name_label: 'Nombre y apellidos',
    form_name_placeholder: 'Brienne of Tarth',
    form_name_validation_short: '¡Por favor ingresa tu nombre y apellidos!',
    form_name_validation_emoji:
      'A nosotros también nos encantan los emojis, pero desafortunadamente, no son muy buenos para los nombres. Por ahora, limitémonos a las letras.',
    form_name_validation_punctuation_marks:
      'Lo siento, no puedes incluir los siguientes caracteres: @ ] } [ {',
    form_signup_button: 'Regístrate',
    form_disclaimer:
      'Al registrarse, aceptas nuestras <1> condiciones de uso </1> y <3> políticas </3>.',
    create_free_account: 'Crea una cuenta nueva',
    already_have_account: '¿Ya tienes una cuenta?',
    no_account_yet: '¿No tienes cuenta aún?',

    work_email_promote:
      'Recomendamos usar tu correo de trabajo o escuela para mantener todo separado',
  },
};

const deDE: Locale = {
  authPackage: {
    log_in_to_menti_account: 'Melden Sie sich bei Ihrem Mentimeter-Konto an',
    login_facebook: 'Anmeldung mit Facebook',
    login_google: 'Anmeldung mit Google',
    login_microsoft: 'Anmeldung mit Microsoft',
    authenticate_sso: 'Authenticate with SSO',
    forgot_password: 'Passwort vergessen?',
    login_sso: 'Anmeldung mit SSO',
    login: 'Einloggen',
    login_form_password_label: 'Dein Passwort',

    signup_facebook: 'Registrierung mit Facebook',
    signup_google: 'Registrierung mit Google',
    signup_microsoft: 'Registrierung mit Microsoft',
    sso_hint_title: 'Treten Sie dem Arbeitsbereich von {{companyName}}',
    sso_hint_body:
      'Ihre Organisation hat bereits einen Mentimeter-Arbeitsbereich, dem Sie beitreten können.',
    sso_hint_button_text: 'Anmelden mit SSO',
    workspace_hint_title: 'Treten Sie dem Arbeitsbereich von {{companyName}}',
    workspace_hint_body:
      'Ihre Organisation hat bereits einen Mentimeter-Arbeitsbereich, dem Sie beitreten können.',
    workspace_hint_button_text: 'Registrierung',
    or_email: 'oder E-Mail-Adresse verwenden',

    form_required: 'Bitte fülle dieses Feld aus!',
    form_email_label: 'Berufliche E-Mail-Adresse',
    form_email_validation: 'Hast du die E-Mail-Adresse richtig eingegeben?',
    form_email_spellcheck: 'Meintest du {{suggestion}}?',
    form_password_label: 'Passwort auswählen',
    form_password_placeholder: 'Sehr geheimes Passwort',
    form_password_hint: 'Mindestens {{passwordMinLength}} Zeichen',
    form_password_hint_signup: 'Mindestens 8 Zeichen',
    form_password_validation_short:
      'Bist du nicht ein bisschen kurz für ein Passwort?',
    form_password_validation_long:
      'Passwörter dürfen nicht länger als 50 Zeichen sein',

    form_name_label: 'Vor- und Nachname',
    form_name_placeholder: 'Brienne of Tarth',
    form_name_validation_short:
      'Bitte gib sowohl deinen Vor- als auch deinen Nachnamen an!',
    form_name_validation_emoji:
      'Wir lieben Emojis, aber leider sind sie nicht besonders gut für Namen. Für den Moment beschränke dich daher bitte auf Buchstaben.',
    form_name_validation_punctuation_marks:
      'Tut mir leid, folgende Zeichen nicht möglich: @ ] } [ {',
    form_signup_button: 'Registrieren',
    form_disclaimer:
      'Mit der Registrierung akzeptierst du unsere <1> Nutzungsbedingungen</1> und <3> Richtlinien </3>.',
    create_free_account: 'Ein kostenloses Konto erstellen',
    already_have_account: 'Sie haben bereits ein Konto?',
    no_account_yet: 'Noch kein Konto?',

    work_email_promote:
      'Nutze deine berufliche E-Mail-Adresse, damit du besser den Überblick behältst',
  },
};

const resourceBundle = {
  'en-US': enUs,
  'pt-BR': ptBr,
  'es-ES': esEs,
  'de-DE': deDE,
};

export default resourceBundle;
