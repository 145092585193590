/* eslint-disable menti-react/forbidden-filename */
import type { i18n } from 'i18next';

export interface Resource {
  [locale: string]: ResourceLanguage;
}

interface ResourceLanguage {
  [namespace: string]: ResourceKey;
}

interface ResourceKey {
  [key: string]: any;
}

export function addResourceBundles(
  i18nextInstance: Pick<i18n, 'addResourceBundle'>,
  resourceBundles: Resource[],
) {
  resourceBundles.forEach((resource) =>
    Object.entries(resource).forEach(([locale, resourceLanguage]) =>
      Object.entries(resourceLanguage).forEach(([namespace, resourceKey]) => {
        i18nextInstance.addResourceBundle(locale, namespace, resourceKey);
      }),
    ),
  );
}

const resources: Array<Resource> = [];

export const addResourcesToInstance = (
  i18nextInstance: Pick<i18n, 'addResourceBundle'>,
) => {
  resources.forEach((resource) =>
    Object.entries(resource).forEach(([locale, resourceLanguage]) =>
      Object.entries(resourceLanguage).forEach(([namespace, resourceKey]) => {
        i18nextInstance.addResourceBundle(locale, namespace, resourceKey);
      }),
    ),
  );
};

/**
 * Merges all registered resource bundles and returns as a valid configuration
 * for i18next initialization.
 */
export const getResourcesAsConfig = () => {
  const config = resources.reduce((acc, resourceBundles) => {
    Object.entries(resourceBundles).forEach(([locale, resourceLanguage]) => {
      acc[locale] ||= {};
      Object.entries(resourceLanguage).forEach(([namespace, resourceKey]) => {
        acc[locale]![namespace] ||= {};
        acc[locale]![namespace] = {
          ...acc[locale]![namespace],
          ...resourceKey,
        };
      });
    });

    return acc;
  }, {});

  return config;
};

/**
 * Registers resource bundles so that they can be added during i18n initialization
 * or directly if instance has already been initialized.
 *
 * @example
 * registerResourceBundles({'en-US': {'auth': {'login': 'Log in'}}});
 */
export function registerResourceBundles(resourceBundles: Resource) {
  resources.push(resourceBundles);
}

/**
 * Only for clean up in unit tests
 */
export function __clearResourceBundlesForTest() {
  resources.splice(0, resources.length);
}
