import * as React from 'react';
import { usePathname } from '@mentimeter/next-navigation';

export interface MobileMenuContextT {
  isOpen: boolean;
  open: () => void;
  close: () => void;
}

const MobileMenuContext = React.createContext<MobileMenuContextT | null>(null);
MobileMenuContext.displayName = 'MobileMenuContext';

export const MobileMenuProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const pathname = usePathname();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const close = React.useCallback(() => setIsOpen(false), []);
  const open = React.useCallback(() => setIsOpen(true), []);

  // Close the menu when we navigate
  React.useEffect(() => {
    close();
  }, [close, pathname]);

  return (
    <MobileMenuContext.Provider value={{ isOpen, close, open }}>
      {children}
    </MobileMenuContext.Provider>
  );
};

export const useMobileMenu = (): MobileMenuContextT => {
  const context = React.useContext(MobileMenuContext);
  if (!context)
    throw Error(`useMobileMenu must be used inside of a MobileMenuProvider`);

  return context;
};
