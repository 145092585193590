import { themes } from '@mentimeter/ragnar-colors';
import type { DSC } from '@mentimeter/ragnar-dsc';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';

const defaultThemes = {
  lightTheme: { ...designSystemConfig, ...themes.light },
  darkTheme: { ...designSystemConfig, ...themes.dark },
};

/**
 *
 * This function returns the theme based on user preference.
 *
 * It is using function overloading to provide a better developer experience.
 * When providedThemes parameter is provided, the return type is the type of the providedThemes
 * other wise it is DSC
 *
 */
export function createDscFromThemeSetting(themeSetting: string | undefined): {
  theme: DSC;
  themeClass: string;
};

export function createDscFromThemeSetting<T extends DSC>(
  themeSetting: string | undefined,
  providedThemes: { darkTheme: T; lightTheme: T },
): { theme: T; themeClass: string };

export function createDscFromThemeSetting<T extends DSC>(
  themeSetting: string | undefined,
  { darkTheme, lightTheme } = defaultThemes,
): { theme: T | DSC; themeClass: string } {
  let uiTheme = themeSetting;

  if (
    themeSetting === 'system-preference' &&
    typeof window !== 'undefined' &&
    window.matchMedia
  ) {
    // Client-side-only code
    const { matches } = window.matchMedia('(prefers-color-scheme: dark)');
    uiTheme = matches ? 'dark' : 'light';
  }

  const theme = uiTheme === 'dark' ? darkTheme : lightTheme;
  const themeClass = themeSetting || 'light';

  return { theme, themeClass };
}
