import Cookies from '@mentimeter/cookies';
import { LocalStorage } from '@mentimeter/storage';
import { USER_LOCALE_STORAGE_KEY } from './constants';

export const getUserLocale = () => {
  const cookieLocale = Cookies.get(USER_LOCALE_STORAGE_KEY);

  if (cookieLocale) return cookieLocale;

  return LocalStorage.getItem(USER_LOCALE_STORAGE_KEY) || undefined;
};
