'use client';

import {
  RegionalDataFetchersProvider,
  getRegionalResultsFetcher,
  getRegionalSeriesFetcher,
  getRegionalQuizPlayersScoreFetcher,
} from '@mentimeter/core-hooks';

const REGIONS = {
  eu: {
    blabUrl: process.env.NEXT_PUBLIC_EU_TRACKING_URL,
    coreUrl: process.env.NEXT_PUBLIC_EU_CORE_URL,
    quizUrl: process.env.NEXT_PUBLIC_EU_QUIZ_URL,
    imgixDomain: process.env.NEXT_PUBLIC_IMGIX_DOMAIN_EU,
  },
  us: {
    blabUrl: process.env.NEXT_PUBLIC_US_TRACKING_URL,
    coreUrl: process.env.NEXT_PUBLIC_US_CORE_URL,
    quizUrl: process.env.NEXT_PUBLIC_US_QUIZ_URL,
    imgixDomain: process.env.NEXT_PUBLIC_IMGIX_DOMAIN_US,
  },
} as const;

export function RegionalProvider({ children }: { children: React.ReactNode }) {
  return (
    <RegionalDataFetchersProvider
      value={{
        getResults: getRegionalResultsFetcher(REGIONS),
        getQuizPlayersScore: getRegionalQuizPlayersScoreFetcher(REGIONS),
        getSeries: getRegionalSeriesFetcher(REGIONS),
      }}
    >
      {children}
    </RegionalDataFetchersProvider>
  );
}
