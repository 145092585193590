const resourceBundle = {
  'en-US': {
    billingAddressPackage: require('./en-US/billingAddressPackage.json'),
  },
  'de-DE': {
    billingAddressPackage: require('./de-DE/billingAddressPackage.json'),
  },
  'pt-BR': {
    billingAddressPackage: require('./pt-BR/billingAddressPackage.json'),
  },
  'es-ES': {
    billingAddressPackage: require('./es-ES/billingAddressPackage.json'),
  },
};

export default resourceBundle;
