'use client';
import { setUser as setSentryUser } from '@sentry/nextjs';
import User from '@mentimeter/user';

if (User) {
  setSentryUser({ id: String(User.id) });
}

export default function Sentry() {
  return null;
}
