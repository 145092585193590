const resourceBundle = {
  'en-US': {
    checkoutModalPackage: require('./en-US/checkoutModalPackage.json'),
  },
  'de-DE': {
    checkoutModalPackage: require('./de-DE/checkoutModalPackage.json'),
  },
  'pt-BR': {
    checkoutModalPackage: require('./pt-BR/checkoutModalPackage.json'),
  },
  'es-ES': {
    checkoutModalPackage: require('./es-ES/checkoutModalPackage.json'),
  },
};

export default resourceBundle;
