import(/* webpackMode: "eager" */ "/home/runner/work/mm-js/mm-js/applications/dashboard/public/legacy-www-styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/home/runner/work/mm-js/mm-js/applications/dashboard/src/app/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/mm-js/mm-js/applications/dashboard/src/app/Sentry.ts");
;
import(/* webpackMode: "eager", webpackExports: ["NoScript"] */ "/home/runner/work/mm-js/mm-js/applications/dashboard/src/components/NoScript.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mm-js/mm-js/design/ragnar-tailwind-config/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/mm-js/mm-js/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["Modal"] */ "/home/runner/work/mm-js/mm-js/packages/legacy-modal/src/modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MazeScript"] */ "/home/runner/work/mm-js/mm-js/packages/maze/src/MazeScript.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toasts"] */ "/home/runner/work/mm-js/mm-js/packages/toast/src/toasts.tsx");
