/* eslint-disable menti-react/filename-convention--jsx */
import {
  core,
  type QuizPlayerScore,
  type Series,
  type SessionResult,
} from '@mentimeter/http-clients';
import { getRegionBySeriesId } from '@mentimeter/region';
import React, { createContext, useContext } from 'react';

interface RegionalDataFetchersContext {
  getSeries(seriesId: string): Promise<Series>;
  getResults(seriesId: string, questionId: string): Promise<SessionResult>;
  getQuizPlayersScore(
    seriesId: string,
    questionId: string,
    clearCache: boolean,
  ): Promise<QuizPlayerScore[]>;
}

const RegionalDataFetchers = createContext<RegionalDataFetchersContext | null>(
  null,
);

/**
 * Returns series-region aware fetchers. For example, in the case we want to fetch a template that lives in a region different than that of the user.
 * @returns
 */
export function useRegionalDataFetchers(): RegionalDataFetchersContext {
  const context = useContext(RegionalDataFetchers);
  if (context === null)
    throw new Error('Missing provider for RegionalDataFetchers context');

  return context;
}

export function RegionalDataFetchersProvider({
  value,
  children,
}: {
  value: RegionalDataFetchersContext;
  children: React.ReactNode;
}) {
  return (
    <RegionalDataFetchers.Provider value={value}>
      {children}
    </RegionalDataFetchers.Provider>
  );
}

export function getRegionalSeriesFetcher(
  regions: any,
): (seriesId: string) => Promise<Series> {
  return async (seriesId) => {
    const region = getRegionBySeriesId(seriesId);
    const baseURL = regions[region].coreUrl;

    const { data } = await core().series.get(seriesId, { baseURL });

    return data;
  };
}

export function getRegionalResultsFetcher(
  regions: any,
): (seriesId: string, questionId: string) => Promise<SessionResult> {
  return async (seriesId, questionId) => {
    const region = getRegionBySeriesId(seriesId);
    const baseURL = regions[region].coreUrl;

    const { data } = await core().questions.result.get(
      questionId,
      {},
      { baseURL },
    );

    return data;
  };
}

export function getRegionalQuizPlayersScoreFetcher(
  regions: any,
): (
  seriesId: string,
  questionId: string,
  clearCache: boolean,
) => Promise<QuizPlayerScore[]> {
  return async (seriesId, questionId, clearCache) => {
    const region = getRegionBySeriesId(seriesId);
    const baseURL = regions[region].coreUrl;

    const { data } = await core().questions.quizPlayers.get(
      questionId,
      clearCache,
      {
        baseURL,
      },
    );

    return data;
  };
}
