import { useRef, useEffect } from 'react';
import type { CallbackFn } from './types';

/** A wrapper around setInterval that plays better with React hooks.
 * @example useInterval(() => console.log('hej'), 5) // this will be executed every 5 ms
 * @example useInterval(() => console.log('hej'), null) // this will pause execution
 */
export const useInterval = (callback: CallbackFn, delay: number | null) => {
  const savedCallback = useRef<CallbackFn | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect((): void | (() => void) => {
    function tick() {
      if (savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        return clearInterval(id);
      };
    }
  }, [delay]);
};
