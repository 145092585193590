import { getUserLocale } from '@mentimeter/i18n';
import type { NextI18NextInitConfig } from '../i18n/src';

const lng = getUserLocale();

const i18nextConfig: NextI18NextInitConfig = {
  supportedLngs: ['en-US', 'pt-BR', 'es-ES', 'de-DE'],
  fallbackLng: 'en-US',
  react: {
    useSuspense: false,
  },
  ...(lng ? { lng } : {}),
};

export default i18nextConfig;
