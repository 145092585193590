export const invoiceRequestBundle = {
  'en-US': { invoiceRequest: require('./en-US/invoiceRequest.json') },
  'de-DE': { invoiceRequest: require('./de-DE/invoiceRequest.json') },
  'pt-BR': { invoiceRequest: require('./pt-BR/invoiceRequest.json') },
  'es-ES': { invoiceRequest: require('./es-ES/invoiceRequest.json') },
};

export const planBoxBundle = {
  'en-US': { planBox: require('./en-US/planBox.json') },
  'de-DE': { planBox: require('./de-DE/planBox.json') },
  'pt-BR': { planBox: require('./pt-BR/planBox.json') },
  'es-ES': { planBox: require('./es-ES/planBox.json') },
};
